import "core-js/modules/es.array.push.js";
import * as local from '@/libs/local';
import * as gongju from '@/libs/gongju';
import Decimal from 'decimal.js';
//import HelloWorld from '@/components/HelloWorld.vue';
import { showToast } from 'vant';
import Vue from 'vue';
import { Swipe, SwipeItem } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Grid, GridItem } from 'vant';
import * as api from '@/api';
import { ref, onMounted } from 'vue';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { showDialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { Notify } from 'vant';
import { Popover } from 'vant';
import { Circle } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import Header from '../lyout/header.vue';
import Footer from '../lyout/footer.vue';
import Ce from '../lyout/ce.vue';
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Ce
  },
  setup() {
    // 使用ref引用DOM元素  
    const scrollContainer = ref(null);
    function scrollToCenter() {
      if (scrollContainer.value) {
        // 计算所有子元素的总宽度  
        console.log(scrollContainer.value.children[0].offsetWidth);
        let to = scrollContainer.value.children[0].offsetWidth / 6;
        // 设置滚动容器的scrollLeft属性  
        scrollContainer.value.scrollLeft = to;
      }
    }

    // 在组件挂载后调用scrollToCenter方法  
    onMounted(() => {
      scrollToCenter();
    });
    return {
      scrollContainer
    };
  },
  data() {
    return {
      search_value: '',
      banner: [],
      news: [],
      lang_list: [],
      select_lang: {},
      lang: {},
      cate: [],
      user: {},
      showPopover: false,
      all: [{
        top: -0.7,
        class: "1"
      }],
      count: 10,
      jizi: [],
      text: "7/10",
      chken: {},
      currentRate: 0,
      currentRate1: 0,
      ji: {
        ce1: 0,
        ce2: 100
      },
      dao: [],
      app_root: api.base(),
      head_bg: ""
    };
  },
  created: function () {
    let _this = this;
    let init = 0;

    //  console.log(this.all)
    _this.get_imdex_data();
    this.head_bg = local.readInfo('head_bg');
  },
  // mounted() {
  //   // 组件挂载完成后的副作用操作
  //   console.log('组件已挂载到DOM');
  //   // 可以执行DOM操作或数据请求等
  //   console.log(scrollContainer)
  // },
  methods: {
    left() {
      this.$refs.swiper.prev();
    },
    right() {
      this.$refs.swiper.next();
    },
    onClickLeft() {
      api.chat(-1);
    },
    //切换语言
    select(e) {
      local.saveInfo('setting_lang', e);
      window.location.reload();
    },
    get_imdex_data: function () {
      console.log(111111);
      api.all('/api/index/index', {}, (err, data) => {
        if (!err && data.code === 1) {
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.lang = data.data.lang;
          this.user = data.data.user;
          this.dao = data.data.dao;
          this.banner = data.data.banner;
          this.tan = data.data.tan;
          console.log(this.tan);
          if (this.tan) {
            setTimeout(() => {
              //this.tc_show=true;
              showDialog({
                title: this.tan.name,
                message: this.tan.describe,
                width: "96%",
                messageAlign: "left",
                className: "showbox",
                confirmButtonText: "OK"
              }).then(() => {
                // on close
              });
            }, 1500);
          }
        } else {
          console.log(data);
          //  if(data&&'msg' in data){
          //    Toast(data.msg)
          //  }
          //this.block = false
        }
      });
    },
    goto: function (name) {
      let _this = this;
      _this.$router.push({
        name: name,
        query: {
          id: 1
        }
      });
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 滚动到顶部
      });
    },
    to_dao: function (id1) {
      let _this = this;
      _this.$router.push({
        name: 'detail',
        query: {
          id: id1
        }
      });
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 滚动到顶部  
      });
    },
    news_list: function () {
      let _this = this;
      _this.$router.push({
        name: 'ac_list',
        query: {
          id: 0
        }
      });
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 滚动到顶部  
      });
    }
  }
};